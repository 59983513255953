<template>
  <v-container id="SiteCreateView" class="px-8" fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <div>
            <div class="text-h5 font-weight-bold">Create New Site</div>
            <v-breadcrumbs
              class="px-0 py-2"
              :items="breadcrumbs"
              divider="/"
            ></v-breadcrumbs>
          </div>
          <v-btn
            small
            depressed
            color="primary"
            :disabled="!enable_create_btn"
            @click.prevent="createNewSiteBtnClicked"
          >
            Save
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="pb-0">
        <span class="text-subtitle-1 font-weight-medium">
          Site Information</span
        >
      </v-col>
      <v-col cols="4">
        <v-text-field
          dense
          outlined
          clearable
          label="Site Name"
          placeholder="Site Name"
          v-model="siteName"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          dense
          outlined
          clearable
          label="Project Code"
          placeholder="Project Code"
          v-model="projectCode"
        />
      </v-col>
      <v-col cols="4">
        <v-select
          dense
          outlined
          clearable
          label="IoT Hub"
          item-text="name"
          item-value="_id"
          :items="hub_list"
          v-model="selectedHub"
        >
          <template v-slot:item="{ item }">
            <div class="py-3">
              {{ item.name }}
              <span class="grey--text text-lighten-1"
                >( {{ item.hubName }} )</span
              >
            </div>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <UnsavedDialog ref="confirm" />
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import UnsavedDialog from "@/components/dialogs/UnsavedDialog";

export default {
  name: "SiteCreateView",
  components: {
    UnsavedDialog,
  },
  data() {
    return {
      siteName: null,
      projectCode: null,
      selectedHub: null,
      breadcrumbs: [
        {
          text: "Sites",
          exact: true,
          disabled: false,
          to: { name: "site" },
        },
        {
          text: "Create New Site",
          exact: true,
          disabled: true,
          to: { name: "site-create" },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      hub_list: (state) => state.hub.hub_list,
      unsaved_ans: (state) => state.hub.unsaved_ans,
    }),
    is_editing: function () {
      return Boolean(this.siteName || this.projectCode || this.selectedHub);
    },
    enable_create_btn: function () {
      return Boolean(this.siteName && this.projectCode && this.selectedHub);
    },
  },
  methods: {
    ...mapMutations({
      displayUnsavedDialog: "DISPLAY_UNSAVED_DIALOG",
    }),
    ...mapActions({
      requestGetHubList: "requestGetHubList",
      requestCreateNewSite: "requestCreateNewSite",
    }),
    async createNewSiteBtnClicked() {
      if (this.enable_create_btn) {
        const params = {
          siteName: this.siteName,
          code: this.projectCode,
          iotHubId: this.selectedHub,
        };
        await this.requestCreateNewSite(params);
        this.clearDataAfterSiteCreated();
      }
    },
    clearDataAfterSiteCreated() {
      this.siteName = null;
      this.projectCode = null;
      this.selectedHub = null;
    },
  },
  async beforeMount() {
    await this.requestGetHubList();
  },
  beforeRouteLeave(to, from, next) {
    // IF any inputs were filled, determine as unsaved
    if (this.is_editing) {
      this.$refs.confirm.open().then((answer) => {
        if (answer) {
          next();
        } else {
          next(false);
        }
      });
    } else {
      next();
    }
  },
};
</script>
