<template>
  <v-overlay id="UnsavedDialog" :opacity="0.3" :value="dialog">
    <v-dialog v-model="dialog" persistent max-width="380">
      <v-card class="text-center mx-auto" flat rounded="lg" color="nav_color">
        <v-card-title class="primary--text font-weight-bold justify-center">
          Unsaved Changes
        </v-card-title>
        <v-divider class="mx-4"></v-divider>

        <v-card-text class="mt-4">
          <span class="font-weight-medium">
            Do you really want to leave? you have unsaved changes!
          </span>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-actions class="justify-center py-3">
          <v-btn
            rounded
            outlined
            depressed
            color="grey"
            class="px-4"
            @click.native="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            rounded
            depressed
            color="primary"
            class="px-4"
            @click.native="agree"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  name: "UnsavedDialog",
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: "primary",
      width: 290,
      zIndex: 200,
    },
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
